<script setup lang="ts">
import { usePlayerStore } from '~/stores/usePlayerStore'
import { useCookieConsentStore } from '~/stores/useCookieConsentStore'

const playerStore = usePlayerStore()
const ccStore = useCookieConsentStore()
const route = useRoute()
const { $viewport } = useNuxtApp()

const showAd = computed(() => {
  return (
    ccStore.isTargetingAccepted &&
    $viewport.isGreaterOrEquals('xl') &&
    (route.name === 'radio-id' || route.name === 'podcast-podcastId') &&
    (route.params.id === 'development' ||
      route.params.id === 'sheil-feb-2021' ||
      route.params.podcastId === 'new-podcast-2-3')
  )
})
</script>

<template>
  <div class="min-w-80 relative">
    <div
      v-if="$viewport.isGreaterOrEquals('lg')"
      class="lg:flex hidden w-60 h-screen p-4 fixed flex-col gap-8 bg-zeno-gray-light z-10"
      :class="{ 'pb-28': playerStore.media }"
    >
      <div class="lg:pl-4">
        <MainLogo />
      </div>

      <MainNavigation />
    </div>

    <div v-else class="lg:hidden z-10">
      <MobileNavigation />
    </div>

    <div class="lg:pl-60 pt-8 pb-32 relative" :class="{ 'xl:pr-80': showAd }">
      <div class="lg:px-8 px-4">
        <div class="max-w-screen-lg mx-auto">
          <slot />
        </div>
      </div>
    </div>

    <div v-if="showAd" class="w-80 fixed right-0 top-1/2 -translate-y-1/2 z-10" :class="{ 'xl:block': showAd }">
      <GoogleAd
        ad-unit-id="div-gpt-ad-1730815690760-0"
        ad-unit-path="/21869305879/ZenoFM_300x250"
        :width="300"
        :height="250"
        :targeting="{ page_url: route.path }"
      />
    </div>
  </div>
</template>

<style scoped></style>
